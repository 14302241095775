/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Button from 'components/BloomForms/Button';
import TreeSelector from 'components/NewTreeSelector';
import AutoCompleteField from 'components/PageTitleAndFilter/AutoCompleteField/AutoCompleteField';
import DateInputField from 'components/PageTitleAndFilter/DateInputField/DateInputField';
import DateRangeField from 'components/PageTitleAndFilter/DateRangeField/DateRangeField';
import {
  TreeSelectorWrapper,
  FilterLabel
} from 'components/PageTitleAndFilter/index.style';
import InputFieldSearch from 'components/PageTitleAndFilter/InputFieldSearch/InputFiledSearch';
import MultipleCheckbox from 'components/PageTitleAndFilter/MultipleCheckbox/MultipleCheckbox';
import MultipleRadiobox from 'components/PageTitleAndFilter/MultipleRadiobox/MultipleRadiobox';
import SwitchField from 'components/PageTitleAndFilter/SwitchField/SwitchField';
import EngineMobileIcon from 'images/icons/configsAndSetups/engineMobileIcon.svg';
import EngineMobileIconBlue from 'images/icons/configsAndSetups/engineMobileIconBlue.svg';
import FilterMobileIcon from 'images/icons/filters/filterMobileIcon.svg';
import FilterMobileIconBlue from 'images/icons/filters/filterMobileIconBlue.svg';

import IconBackArrow from './iconBackArrow.svg';
import {
  PageAndTitleWrapper,
  PageTitle,
  FilterPageContainer,
  SubTitle,
  FilterInputsWrapper,
  InputFilterCase,
  BackButtonWrapper,
  SettingsCard,
  ApplyFilterWrapper
} from './index.style';

function PageAndTitleFilterMobile({
  title,
  status,
  subTitle,
  settings = false,
  activeFilterFilds = [],
  inputSizes,
  handleFilter,
  getFilterhandle,
  handleMultipleFilters,
  topAdjustment,
  onChange,
  backButton = null,
  filterOff = false,
  settingsMobile = [],
  closeSettings
}) {
  const [openFilter, setOpenFilter] = useState(false);
  const [openSettingsOptions, setHandleOpenSettingsOptions] = useState(false);

  const filterInputRef = useRef(null);

  const handleFilterOpen = () => {
    setOpenFilter(!openFilter);
  };

  const handleOpenSettingsOptions = () => {
    setHandleOpenSettingsOptions(!openSettingsOptions);
  };

  const handleClickAway = () => {
    setOpenFilter(false);
    setHandleOpenSettingsOptions(false);
  };

  useEffect(() => {
    setHandleOpenSettingsOptions(false);
  }, [closeSettings]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <PageAndTitleWrapper className="filterBtn">
        <PageTitle>
          {backButton && (
            <BackButtonWrapper onClick={() => backButton.action()}>
              <p>
                {' '}
                <img
                  src={IconBackArrow}
                  alt="back arrow icon"
                  className="mr5"
                />{' '}
                {backButton.description}
              </p>
            </BackButtonWrapper>
          )}

          <div className="dFlex alignCenter">
            <p className="uppercase mb0">{title}</p>
            {status && <span style={{ marginLeft: '10px' }}>{status}</span>}
          </div>

          <SubTitle>{subTitle}</SubTitle>
        </PageTitle>
        
        <div className="dFlex">
          {!filterOff && (
            <FilterPageContainer blue={openFilter}>
              <img
                src={openFilter ? FilterMobileIconBlue : FilterMobileIcon}
                alt="filter icon"
                onClick={handleFilterOpen}
                ref={filterInputRef}
                style={{ padding: '12px' }}
              />
            </FilterPageContainer>
          )}

          {settings && (
            <FilterPageContainer blue={openSettingsOptions}>
              <img
                src={
                  openSettingsOptions ? EngineMobileIconBlue : EngineMobileIcon
                }
                alt="engine icon"
                onClick={handleOpenSettingsOptions}
              />
            </FilterPageContainer>
          )}
        </div>

        <FilterInputsWrapper open={openSettingsOptions}>
          {settingsMobile.map(
            ({
              description = '',
              action = () => {},
              backgroundBlue = false,
              styleType = null,
              bold = false
            }) => (
              <SettingsCard
                backgroundBlue={backgroundBlue}
                onClick={action}
                styleType={styleType}
                bold={bold}
              >
                {description}
              </SettingsCard>
            )
          )}
        </FilterInputsWrapper>

        <FilterInputsWrapper open={openFilter} ref={filterInputRef}>
          {activeFilterFilds.map((filterLine, activeFilterIndex) => {
            const filterLineSize = filterLine.length;

            return (
              <>
                {filterLine.map(
                  ({
                    urlPath,
                    label,
                    key,
                    optionReference,
                    placeHolder,
                    type,
                    multiple,
                    options,
                    filterName,
                    filterNameFirst,
                    filterNameSecond,
                    checkBoxInputs,
                    size,
                    internalCheckBox,
                    initialDate,
                    endDate,
                    minDate,
                    maxDate,
                    isExtract,
                    disabled,
                    disabledDays,
                    tooltipText,
                    defaultValue,
                    actionButtons,
                    views,
                    dateFormat,
                    updateDefaultValue,
                    inputOptions,
                    resetDateWithDelete
                  }) => (
                    <InputFilterCase
                      ref={filterInputRef}
                      width100={type === 'dateRangeField'}
                      style={{ width: type === 'multipleCheckbox' && '50%' }}
                    >
                      {type === 'autoComplete' && (
                        <AutoCompleteField
                          urlPath={urlPath}
                          label={label}
                          keyField={key}
                          multiple={multiple}
                          optionReference={optionReference}
                          placeHolder={placeHolder}
                          options={options}
                          filterName={filterName}
                          handleFilter={handleFilter}
                          size="large"
                          inputSizes={inputSizes}
                          internalCheckBox={internalCheckBox}
                          getFilterhandle={getFilterhandle}
                          defaultValue={defaultValue}
                          updateDefaultValue={updateDefaultValue}
                          actionButtons={actionButtons}
                        />
                      )}

                      {type === 'fieldSearch' && (
                        <InputFieldSearch
                          label={label}
                          placeHolder={placeHolder}
                          filterName={filterName}
                          handleFilter={handleFilter}
                          size="large"
                          inputSizes={inputSizes}
                        />
                      )}

                      {type === 'multipleCheckbox' && (
                        <MultipleCheckbox
                          title={label}
                          checkBoxInputs={checkBoxInputs}
                          handleFilter={handleFilter}
                          topAdjustment={topAdjustment(
                            activeFilterIndex,
                            label
                          )}
                        />
                      )}
											
                      {type === 'multipleRadiobox' && (
                        <MultipleRadiobox
                          title={label}
                          checkBoxInputs={checkBoxInputs}
                          handleFilter={handleFilter}
                          topAdjustment={topAdjustment(
                            activeFilterIndex,
                            label
                          )}
                        />
                      )}

                      {type === 'dateInputField' && (
                        <DateInputField
                          size={size}
                          handleFilter={handleFilter}
                          inputSizes={inputSizes}
                          label={label}
                          filterName={filterName}
                          initialDate={initialDate}
                          minDate={minDate && minDate}
                          maxDate={maxDate && maxDate}
                          isExtract={isExtract}
                          disabled={disabled}
                          disabledDays={disabledDays}
                          tooltipText={tooltipText}
                          resetDateWithDelete={resetDateWithDelete}
                        />
                      )}
											
                      {type === 'range' && (
                        <div className="dFlex flexColumn rangeFilter">
                          <label className="fakeLabel">{label}</label>

                          <div className="dFlex alignEnd inputBox">
                            <InputFieldSearch
                              placeHolder={placeHolder}
                              filterName={filterNameFirst}
                              handleFilter={handleFilter}
                              inputSizes={inputSizes}
                              withoutIcon
                            />

                            <span>até</span>

                            <InputFieldSearch
                              placeHolder={placeHolder}
                              filterName={filterNameSecond}
                              handleFilter={handleFilter}
                              inputSizes={inputSizes}
                              withoutIcon
                            />
                          </div>
                        </div>
                      )}

                      {type === 'dateRangeField' && (
                        <DateRangeField
                          size={size}
                          handleFilter={handleMultipleFilters}
                          inputSizes={inputSizes}
                          filterNameStart={filterNameFirst}
                          filterNameFinal={filterNameSecond}
                          initialDate={initialDate}
                          endDate={endDate}
                          minDate={minDate && minDate}
                          maxDate={maxDate && maxDate}
                          views={views}
                          dateFormat={dateFormat}
                        />
                      )}

                      {type === 'switchField' && (
                        <SwitchField
                          onChange={onChange}
                          title={label}
                          handleFilter={handleFilter}
                          topAdjustment={topAdjustment(
                            activeFilterIndex,
                            label
                          )}
                          filterName={filterName}
                        />
                      )}

                      {type === 'treeSelector' && (
                        <TreeSelectorWrapper>
                          <FilterLabel>{label}</FilterLabel>
                          <TreeSelector
                            label=""
                            inputOptions={{ ...inputOptions }}
                            setCountCategoryDescription={() => {}}
                            StylePageTitleAnFilter
                            handleFilter={handleFilter}
                            filterName={filterName}
                            placeHolder={placeHolder}
                          />
                        </TreeSelectorWrapper>
                      )}
                    </InputFilterCase>
                  )
                )}
              </>
            );
          })}
					
          <ApplyFilterWrapper>
            <Button
              label="Aplicar filtros"
              primary
              onClick={handleFilterOpen}
              width="auto"
            />
          </ApplyFilterWrapper>
        </FilterInputsWrapper>
      </PageAndTitleWrapper>
    </ClickAwayListener>
  );
}

export default PageAndTitleFilterMobile;
